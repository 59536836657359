<template>
  <div class="login-container">
    <v-snackbar color="error" top v-model="snackbar">
      {{ snackbarText }}
      <v-btn @click="snackbar = false" dark> Закрыть </v-btn>
    </v-snackbar>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="4">
          <v-card
            class="elevation-0"
            style="border: 1px solid rgb(224 224 224); background: #f7f7fa"
          >
            <v-card-title class="card-title" :style="placeColor()">
              <img :src="`/logo_${placeSvg()}.svg`" />
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form ref="form" v-model="valid" validation>
                <v-text-field
                  :rules="[(v) => !!v || 'Логин обязательный']"
                  @keyup.enter="loginBtn"
                  id="email"
                  label="Логин"
                  name="login"
                  required
                  type="text"
                  v-model="login"
                  outlined
                  class="mr-5 mt-5 ml-5"
                ></v-text-field>
                <v-text-field
                  :rules="[(v) => !!v || 'Пароль обязательный']"
                  @keyup.enter="loginBtn"
                  id="password"
                  label="Пароль"
                  name="password"
                  required
                  autocomplete="on"
                  type="password"
                  v-model="password"
                  outlined
                  class="mr-5 ml-5"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions style="height: 65px">
              <v-spacer></v-spacer>
              <v-btn
                style="width: 150px"
                :disabled="!valid"
                @click="loginBtn"
                depressed
                class="mr-6 mb-6 button-white-blue"
                >Войти</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";

export default {
  props: {
    source: String,
  },
  data: () => ({
    valid: false,
    login: "",
    password: "",
    snackbar: false,
    snackbarText: "",
  }),
  created() {},
  methods: {
    placeColor() {
      if (this.$store.state.logoLogin) {
        if (this.$store.state.logoLogin === "course") {
          return "";
        } else {
          return "background-color:#307C1F;";
        }
      }else{
        return "background-color:#307C1F;";
      }
    },
    placeSvg() {
      if (this.$store.state.logoLogin) {
        return this.$store.state.logoLogin;
      } else {
        return "synergetic";
      }
    },
    loginBtn: function () {
      if (this.$refs.form.validate()) {
        let body = {
          login: this.login,
          password: this.password,
        };
        axios
          .post(this.$store.getters.apiAdminPanelV4 + "/auth/login", body)
          .then((res) => {
            this.$cookies.set(
              this.$store.state.adminPanelKeyCookie,
              res.data.oauthToken.token
            );
            this.$router.go(0);
          })
          .catch(() => {
            this.$store.commit("errorSet", true);
            this.$store.commit(
              "errorTextSet",
              "Введен неверный логин или пароль"
            );
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--app-grey-1);
}
.card-title {
  background-color: var(--app-grey-3);
}
</style>
